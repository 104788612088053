export const plansData = [
  {
    id: 1,
    name: 'Comisión Competitiva',
    supply: '1,6%', 
    brokersInfo: 'Máximo 2 corredores',
    characteristics: [
      'Publicación en 30 portales inmobiliarios.',
      'Ofertas formales con garantía.',
      'Evaluación comercial mensual.',
      'Asesoría Legal.',
      'Fotos y videos profesionales.',
      'Estudio de ventas históricas.',
      'Estudio de mercado.',
      'Coordinación de visitas.',
    ],
  },
  {
    id: 2,
    name: 'Comisión Inteligente',
    supply: '1,2%',
    brokersInfo: 'Exclusiva con Aulen Propiedades',
    characteristics: [
      'Publicación en 30 portales inmobiliarios.',
      'Ofertas formales con garantía',
      'Evaluación comercial mensual.',
      'Asesoría legal',
      'Fotos y videos profesionales.',
      'Estudio de ventas históricas.',
      'Estudio de mercado',
      'Coordinación de visitas.',
    ],
  },
  {
    id: 3,
    name: 'Comisión Tradicional',
    supply: '2%',
    brokersInfo: 'Más de 2 corredores',
    characteristics: [
      'Publicación en 30 portales inmobiliarios',
      'Ofertas formales con garantía',
      'Evaluación comercial mensual.',
      'Asesoría legal',
      'Fotos y videos profesionales.',
      'Estudio de ventas históricas.',
      'Estudio de mercado',
      'Coordinación de visitas.',
    ],
  },
];
