export const publishingFormData = [
  {
    id: 1,
    h2: '¡Despreocúpate por tupropiedad de inversión!',
    h3: 'COMPLETA EL FORMULARIO',
    btn: 'ENVIAR',
  },
  {
    id: 2,
    h2: '¡Despreocúpate por tu propiedad de inversión!',
    h3: 'COMPLETA EL FORMULARIO Y ENTÉRATE CÓMO',
    btn: 'Quiero que me contacten',
  },
];
